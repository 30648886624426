  
  .containerimg {
    position: relative;
    margin: 0.3em;
 
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: #353535;    
  }
  
  .containerimg:hover .overlay {
    opacity: 0.8;
    cursor: pointer;
  }

  
  
  .text {
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin-top: 5px;
    text-align: center;
  }



  .textrecenze {
    color: white;
    font-weight: bold;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }

  