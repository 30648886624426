.listitemAnimation {
  -webkit-animation: moveFromRight 1s forwards; /* for less modern browsers */
  animation: moveFromRight 1s forwards;
  opacity: 0;
}
  
@keyframes moveFromRight {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
  
  
 