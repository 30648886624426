.socials:hover {
    color: black;
    text-decoration: none;
    opacity: 0.7 !important;
  }
  
  .socials2:hover {
    color: black;
    text-decoration: none;
    opacity: 0.7 !important;
  }
  
  .socials {
    margin-left: 0.3em;
    width: 20px;
    height: auto;
  }
  
  .socials2 {
    margin-left: 0.5em;
    width: 20px;
    height: auto;
  }
  
  .img-socials {
    width: 28px;
    height: auto;
  }
  
  .img-socials2 {
    width: 40px;
    height: auto;
  }
  
  @media screen and (max-width: 800px) {
    .socials {
      margin-left: 1em;
    }
  }
  .socials-navigation-wrapper {
    padding-top: 5px;
  }
  
  @media screen and (max-width: 800px) {
    .socials-navigation-wrapper {
      text-align: center;
    }
  }
  