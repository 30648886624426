.navlink {
  margin-left: 1em;  
  margin-top: 1em;
  color: white;
  text-decoration: none;
  font-size: x-large;
  display: block;

  text-align: right;
}

@media only screen and (max-width: 600px) {
  .navlink {
    color: white;
    text-decoration: none;
    font-size: x-large;
    display: block;
  
    text-align: right;
  }
}

.navlink_smaller {
  margin-left: 1em;  
  margin-top: 1em;
  width: 100%;
  color: white;
  text-decoration: none;
  font-size: medium;
  text-align: right;
}

.navlink_button {
  margin-left: 1em;  
  margin-top: 1em;
  color: white;
  text-decoration: none;
  font-size: x-large;
  text-align: center;
  border: none;
  cursor: pointer;
  background-color: transparent;
}


.navlink_button:hover {
  margin-left: 1em;  
  color: red;
  text-decoration: none;
}


.navlink:hover {
  margin-left: 1em;  
  color: red;
  text-decoration: none;
}


.navlink_smaller:hover {
  margin-left: 1em;  
  color: red;
  text-decoration: none;
}
