.content {
  margin-left: 2em;
  margin-right: 2em;
  color: white;

}

@media only screen and (max-width: 800px) {
  .content {
    margin-left: 0em;
    margin-right: 0em;
    color: white;
  }
}
