* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  background: rgb(18, 18, 18) !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  overflow-x: hidden;
  color: white;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-panel {
  border-radius: 1em !important;



  max-height: 90vh;
  padding: 1em;
}

.active{
  color: red !important;
}
.bg{
  background-color: #2d3436;
  background-image: linear-gradient(315deg, #2d3436 0%, #000000 74%);
}

.bottom {
  position: absolute;
  bottom: 10px;
  left: 80px;
}

.listitem {
  -webkit-animation: moveFromRight 1s forwards; /* for less modern browsers */
  animation: moveFromRight 1s forwards;
  opacity: 0;
}

.appear_animation {
  -webkit-animation: appear 1s forwards; /* for less modern browsers */
  animation: appear 1s forwards;
  opacity: 0;
}

@keyframes moveFromRight {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
